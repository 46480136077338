export default defineNuxtRouteMiddleware((to, from) => {
    const kukis = useCookie('_xloginsession')
    if (kukis?.value === undefined) {
        useCookie('_xloginsession')

        const w = useCookie('_rza_after_login', { sameSite: 'lax' })
        w.value = from.path
        return navigateTo('/login')
    }
})
